const GlobalMixin = {
    methods: {
        // convert plain string to title case
        titleCase(str) {
            return str?.toLowerCase().split(' ').map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(' ') ?? '';
        },

        // convert underscore string to title case

        camelCase(str) {
            return str?.replace(/_([a-zA-Z])/g, function (m1, m2) {
                return m2.toUpperCase()
            }) ?? '';
        },

        allCaseToTitle(str) {
            return str.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
                .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
        },

        /**
         * @description 1 for date & time 2 for date only 3 for time
         * @param currentDate
         * @param status
         */
        getDateTime(currentDate = new Date(), status) {
            let validDate = currentDate instanceof Date && !isNaN(currentDate.getTime());
            let today = validDate ? new Date(currentDate) : new Date();
            let date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
            let time = ('0' + today.getHours()).slice(-2) + ":" + ('0' + today.getMinutes()).slice(-2) + ":" + ('0' + today.getSeconds()).slice(-2);
            if (!status)
                return date + ' ' + time;
            if (status === 1)
                return date
            if (status === 2)
                return time
        },

        getAustraliaDateTime(timezone) {
            const now = new Date();

            const options = {
                timeZone: timezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            };

            const formatter = new Intl.DateTimeFormat('en-GB', options);
            const formattedParts = formatter.formatToParts(now);

            const formattedDateTime = formattedParts.reduce((acc, part) => {
                switch (part.type) {
                    case 'year':
                        acc.year = part.value;
                        break;
                    case 'month':
                        acc.month = part.value;
                        break;
                    case 'day':
                        acc.day = part.value;
                        break;
                    case 'hour':
                        acc.hour = part.value;
                        break;
                    case 'minute':
                        acc.minute = part.value;
                        break;
                    case 'second':
                        acc.second = part.value;
                        break;
                }
                return acc;
            }, {});

            return `${formattedDateTime.year}-${formattedDateTime.month}-${formattedDateTime.day} ${formattedDateTime.hour}:${formattedDateTime.minute}:${formattedDateTime.second}`;
        },
        /**
         * @description input field only number
         * @param event
         */
        allowOnlyNumbers(event) {
            const key = event.key;
            // Check if the pressed key is a number or control character
            if (!/[0-9]/.test(key) && key !== 'Backspace' && key !== 'Tab') {
                event.preventDefault();
            }
        },
        /**
         * @description input field number input with decimal point
         * @param event
         */
        allowOnlyNumbersWithDecimal(event) {
            const key = event.key;
            const validKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', '.'];
            const currentValue = event.target.value;

            // Allow numbers, control keys, and a single decimal point
            if (!/[0-9]/.test(key) && !validKeys.includes(key) || (key === '.' && currentValue.includes('.'))) {
                event.preventDefault();
            }
        },
        convertTime12To24Format(time) {
            let [hours, minutes] = time.split(/[: ]/); // Split time into hours, minutes, and period (AM/PM)
            let period = time.slice(-2); // Extract AM or PM

            hours = parseInt(hours);

            if (period.toUpperCase() === "PM" && hours !== 12) {
                hours += 12;
            } else if (period.toUpperCase() === "AM" && hours === 12) {
                hours = 0;
            }

            return `${String(hours).padStart(2, "0")}:${minutes.slice(0, 2)}`; // Format as 24-hour
        }

    },
};

export default GlobalMixin;